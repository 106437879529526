.App {
  text-align: center;
}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#gpsIcon{
  font-size:2.5rem;
  cursor:pointer;
  margin-right:0.2rem; 
}
#gpsIcon:hover{
  color:#0344c6;
}

/* #innerContainer{
  @media only screen and (max-width: 600px){
    width:100% !important;
    background-color:white;
  }
} */

@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Noto+Serif+Telugu&family=Tiro+Telugu:ital@1&display=swap');
.fontMerri{
font-family: 'Merriweather Sans', sans-serif !important;
font-family: 'Noto Serif Telugu', serif;
font-family: 'Tiro Telugu', serif;
font-size: 0.8rem;
}


.react-tel-input .form-control{
  width:100% !important;
}


.flex{
  display: grid;
  place-content: center;
  height: 80vh ;
}


.upper-layer {
  /* display: flex;
  position: relative;

  align-items: center; */
  justify-content: space-between;

/*the alpha value controls the transparency*/
  background: rgba( 255, 255, 255, 0.3 );

/* This controls the blurring effect*/
backdrop-filter: blur( 7.5px );
  -webkit-backdrop-filter: blur( 7.5px );

/*Adding the shadow*/
box-shadow: 0 8px 32px 0 rgba( 0, 0, 0, 0.18 );

/* Adding our borders*/
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  z-index: 4;
}

.bgImage{
  /* background-repeat: no-repeat;
  background-image: url('https://static.vecteezy.com/system/resources/thumbnails/002/158/255/small_2x/dark-wood-counter-background-on-blurred-office-background-photo.jpg');
  background-size: 100% 100%; */


background: #B993D6;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #8CA6DB, #B993D6);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #8CA6DB, #B993D6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

.finalPageBg{
  background: #7F00FF;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #E100FF, #7F00FF);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #E100FF, #7F00FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
min-height: 100vh;
max-height: 200vh;

}

.paymentBg{
  background: #A770EF;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FDB99B, #CF8BF3, #A770EF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.exeFormBg{
  background: #8E2DE2;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}

.signUpFormBg{
min-height: 100vh ;
background: #8E2DE2;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

#borderWhite .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border-color: white !important;
  color: white !important;
}


@media (max-width: 450px) {
  .otp-input {
    display: flex;
    justify-content: space-between;
  }
  .otp-input input {
    width: calc(100% / 1); /* Adjust the width of each input cell as needed */
    height: 2.5rem;
    font-size: 12px; /* Adjust the font size as needed */
    padding:2px; /* Adjust the padding as needed */
    box-sizing: border-box;
    text-align: center;
   


  }
}

.otp-input input {
border:1px solid white !important;
border-radius: 5px !important;
color: white !important;  
}


.logoText{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #eab217;  /* fallback for old browsers */
  color: -webkit-linear-gradient(to right, #F37335, #FDC830);  /* Chrome 10-25, Safari 5.1-6 */
  color: linear-gradient(to right, #F37335, #FDC830); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-shadow: 1px 1px 1px rgb(180, 57, 0);
}